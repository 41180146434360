/* Harshith Reddy Vemula (1001876993), Shashank Tatipalli (1002069335), Mohammed Sohail Sayed (1002076185),
 Hemanth Thota (1002084434), Kushal Shah (1002117623)
*/

@import 'https://fonts.googleapis.com/css?family=Montserrat:300, 400, 700&display=swap';
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
html {
	font-size: 10px;
	font-family: 'Montserrat', sans-serif;
	scroll-behavior: smooth;
}
a {
	text-decoration: none;
}
.homecontainer {
	min-height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.homeabout-img img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

p {
	color: black;
	font-size: 1.4rem;
	margin-top: 5px;
	line-height: 2.5rem;
	font-weight: 300;
	letter-spacing: 0.05rem;
}
.homeherosection-title {
	font-size: 4rem;
	font-weight: 300;
	color: black;
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: 0.2rem;
	text-align: center;
}
.homeherosection-title span {
	color: crimson;
}

.homecta {
	display: inline-block;
	padding: 10px 30px;
	color: white;
	background-color: transparent;
	border: 2px solid crimson;
	font-size: 2rem;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	margin-top: 30px;
	transition: 0.3s ease;
	transition-property: background-color, color;
}
.homecta:hover {
	color: white;
	background-color: crimson;
}
.homebrand h1 {
	font-size: 3rem;
	text-transform: uppercase;
	color: white;
}
.homebrand h1 span {
	color: crimson;
}

/* homeheader section */
#homeheader {
	position: fixed;
	z-index: 1000;
	left: 0;
	top: 0;
	width: 100vw;
	height: auto;
}
#homeheader .homeheader {
	min-height: 8vh;
	background-color: rgba(0,0,0,0.6);
	transition: 0.3s ease background-color;
}
#homeheader .homenav-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	max-width: 1300px;
	padding: 0 10px;
}
#homeheader .homenav-list ul {
	list-style: none;
	position: absolute;
	background-color: rgb(31, 30, 30);
	width: 100vw;
	height: 100vh;
	left: 100%;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
	overflow-x: hidden;
	transition: 0.5s ease left;
}
#homeheader .homenav-list ul.active {
	left: 0%;
}
#homeheader .homenav-list ul a {
	font-size: 2.5rem;
	font-weight: 500;
	letter-spacing: 0.2rem;
	text-decoration: none;
	color: white;
	text-transform: uppercase;
	padding: 20px;
	display: block;
}
#homeheader .homenav-list ul a::after {
	content: attr(data-after);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	color: rgba(240, 248, 255, 0.021);
	font-size: 13rem;
	letter-spacing: 50px;
	z-index: -1;
	transition: 0.3s ease letter-spacing;
}
#homeheader .homenav-list ul li:hover a::after {
	transform: translate(-50%, -50%) scale(1);
	letter-spacing: initial;
}
#homeheader .homenav-list ul li:hover a {
	color: crimson;
}
#homeheader .homehamburger {
	height: 60px;
	width: 60px;
	display: inline-block;
	border: 3px solid white;
	border-radius: 50%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	cursor: pointer;
	transform: scale(0.8);
	margin-right: 20px;
}
#homehomeheader .homehamburger:after {
	position: absolute;
	content: '';
	height: 100%;
	width: 100%;
	border-radius: 50%;
	border: 3px solid white;
	animation: omehamburger_puls 1s ease infinite;
}
#homeheader .homehamburger .homebar {
	height: 2px;
	width: 30px;
	position: relative;
	background-color: white;
	z-index: -1;
}
#homeheader .homehamburger .homebar::after,
#homeheader .homehamburger .homebar::before {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	background-color: white;
	transition: 0.3s ease;
	transition-property: top, bottom;
}
#homeheader .homehamburger .homebar::after {
	top: 8px;
}
#homeheader .homehamburger .homebar::before {
	bottom: 8px;
}
#homeheader .homehamburger.active .homebar::before {
	bottom: 0;
}
#homeheader .homehamburger.active .homebar::after {
	top: 0;
}
/* End homeheader section */

/* homehero Section */
#homehero {
	background-image: url(../assets/banner-bg.jpg);
	background-size: cover;
	background-position: top center;
	position: relative;
	z-index: 1;
}
#homehero::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	opacity: 0.7;
	z-index: -1;
}
#homehero .homehero {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 50px;
	justify-content: flex-start;
}
#homehero h1 {
	display: block;
	width: fit-content;
	font-size: 4rem;
	position: relative;
	color: transparent;
	animation: text_reveal 0.5s ease forwards;
	animation-delay: 1s;
}
#homehero h1:nth-child(1) {
	animation-delay: 1s;
}
#homehero h1:nth-child(2) {
	animation-delay: 2s;
}
#homehero h1:nth-child(3) {
	animation: text_reveal_name 0.5s ease forwards;
	animation-delay: 3s;
}
#homehero h1 span {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: crimson;
	animation: text_reveal_box 1s ease;
	animation-delay: 0.5s;
}
#homehero h1:nth-child(1) span {
	animation-delay: 0.5s;
}
#homehero h1:nth-child(2) span {
	animation-delay: 1.5s;
}
#homehero h1:nth-child(3) span {
	animation-delay: 2.5s;
}

/* End homehero Section */

/* Services Section */
#homeservices .homeservices {
	flex-direction: column;
	text-align: center;
	max-width: 1500px;
	margin: 0 auto;
	padding: 100px 0;
}
#homeservices .heroservice-top {
	max-width: 500px;
	margin: 0 auto;
}
#homeservices .homeheroservice-bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 50px;
}
#homeservices .homeheroservice-item {
	flex-basis: 80%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding: 30px;
	border-radius: 10px;
	background-image: url(../assets/img-1.png);
	background-size: cover;
	margin: 10px 5%;
	position: relative;
	z-index: 1;
	overflow: hidden;
}
#homeservices .homeheroservice-item::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
	opacity: 0.9;
	z-index: -1;
}
#homeservices .homeheroservice-bottom .homeheroicon {
	height: 80px;
	width: 80px;
	margin-bottom: 20px;
}
#homeservices .homeheroservice-item h2 {
	font-size: 2rem;
	color: white;
	margin-bottom: 10px;
	text-transform: uppercase;
}
#homeservices .homeheroservice-item p {
	color: white;
	text-align: left;
}
/* End Services Section */

/* About Section */
#homeabout .homeabout {
	flex-direction: column-reverse;
	text-align: center;
	max-width: 1200px;
	margin: 0 auto;
	padding: 100px 20px;
}
#homeabout .homecol-left {
	width: 250px;
	height: 360px;
}
#homeabout .homecol-right {
	width: 100%;
}
#homeabout .homecol-right h2 {
	font-size: 1.8rem;
	font-weight: 500;
	letter-spacing: 0.2rem;
	margin-bottom: 10px;
}
#homeabout .homecol-right p {
	margin-bottom: 20px;
}
#homeabout .homecol-right .homecta {
	color: black;
	margin-bottom: 50px;
	padding: 10px 20px;
	font-size: 2rem;
}
#homeabout .homecol-left .homeabout-img {
	height: 100%;
	width: 100%;
	position: relative;
	border: 10px solid white;
}
#homeabout .homecol-left .homeabout-img::after {
	content: '';
	position: absolute;
	left: -33px;
	top: 19px;
	height: 98%;
	width: 98%;
	border: 7px solid crimson;
	z-index: -1;
}
/* End About Section */

/* contact Section */
#homecontact .homecontact {
	flex-direction: column;
	max-width: 1200px;
	margin: 0 auto;
	width: 90%;
}
#homecontact .homecontact-items {
	width: 100%;
}
#homecontact .homecontact-item {
	width: 80%;
	padding: 20px;
	text-align: center;
	border-radius: 10px;
	padding: 30px;
	margin: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-shadow: 0px 0px 18px 0 #0000002c;
	transition: 0.3s ease box-shadow;
}
#homecontact .homecontact-item:hover {
	box-shadow: 0px 0px 5px 0 #0000002c;
}
#homecontact .homeheroicon {
	width: 70px;
	margin: 0 auto;
	margin-bottom: 10px;
}
#homecontact .homecontact-info h1 {
	font-size: 2.5rem;
	font-weight: 500;
	margin-bottom: 5px;
}
#homecontact .homecontact-info h2 {
	font-size: 1.3rem;
	line-height: 2rem;
	font-weight: 500;
}

.homerow section.homeright .homemessageForm{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 30px;
	margin-top: 30px;
}
.homerow section.homeright .homeinputGroup{
	margin: 18px 0px;
	position: relative;
}
.homemessageForm .halfWidth{
	flex-basis: 48%;
}
.homemessageForm .fullWidth{
	flex-basis: 100%;
}
.homemessageForm input, .homemessageForm textarea{
	width: 100%;
	font-size: 18px;
	padding: 2px 0px;
	background-color: white;
	color: black;
	border: none;
	border-bottom: 2px solid black;
	outline: none;
}
.homemessageForm textarea{
	resize: none;
	height: 220px;
	display: block;
}
.homemessageForm textarea::-webkit-scrollbar{
	width: 5px;
}
.homemessageForm textarea::-webkit-scrollbar-track{
	background-color: #1e1e1e;
	border-radius: 15px;
}
.homemessageForm textarea::-webkit-scrollbar-thumb{
	background-color: crimson;
	border-radius: 15px;
}
.homeinputGroup label{
	position: absolute;
	left: 0;
	bottom: 4px;
	color: #737171;
	font-size: 18px;
	transition: 0.4s;
	pointer-events: none;
}
.homeinputGroup:nth-child(4) label{
	top: 2px;
}
.homeinputGroup input:focus ~ label, .homeinputGroup textarea:focus ~ label,
.homeinputGroup input:valid ~ label, .homeinputGroup textarea:valid ~ label
{
	transform: translateY(-30px);
	font-size: 16px;
	color: crimson;
}
.homeinputGroup button{
	padding: 8px 16px;
	font-size: 18px;
	background-color: transparent;
	border: 2px solid crimson;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	transition: 0.4s;
	color: black;
}
.homeinputGroup button:hover{
	background-color: crimson;
	color: white;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
	border: 1px solid crimson;
}
/*End contact Section */

/* Keyframes */
@keyframes homehamburger_puls {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(1.4);
	}
}
@keyframes text_reveal_box {
	50% {
		width: 100%;
		left: 0;
	}
	100% {
		width: 0;
		left: 100%;
	}
}
@keyframes text_reveal {
	100% {
		color: white;
	}
}
@keyframes text_reveal_name {
	100% {
		color: crimson;
		font-weight: 500;
	}
}
/* End Keyframes */

/* Media Query For Tablet */
@media only screen and (min-width: 768px) {
	.homecta {
		font-size: 2.5rem;
		padding: 20px 60px;
	}
	h1.homeherosection-title {
		font-size: 6rem;
	}

	/* homehero */
	#homehero h1 {
		font-size: 7rem;
	}
	/* End homehero */

	/* Services Section */
	#homeservices .homeheroservice-bottom .homeheroservice-item {
		flex-basis: 45%;
		margin: 2.5%;
	}
	/* End Services Section */

	/* Project */
	#projects .project-item {
		flex-direction: row;
	}
	#projects .project-item:nth-child(even) {
		flex-direction: row-reverse;
	}
	#projects .project-item {
		height: 400px;
		margin: 0;
		width: 100%;
		border-radius: 0;
	}
	#projects .all-projects .project-info {
		height: 100%;
	}
	#projects .all-projects .project-img {
		height: 100%;
	}
	/* End Project */

	/* About */
	#homeabout .homeabout {
		flex-direction: row;
	}
	#homeabout .homecol-left {
		width: 600px;
		height: 400px;
		padding-left: 60px;
	}
	#homeabout .homeabout .homecol-left .homeabout-img::after {
		left: -45px;
		top: 34px;
		height: 98%;
		width: 98%;
		border: 10px solid crimson;
	}
	#homeabout .homecol-right {
		text-align: left;
		padding: 30px;
	}
	#homeabout .homecol-right h1 {
		text-align: left;
	}
	/* End About */

	/* contact  */
	#homecontact .homecontact {
		flex-direction: column;
		padding: 100px 0;
		align-items: center;
		justify-content: center;
		min-width: 20vh;
	}
	#homecontact .homecontact-items {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin: 0;
	}
	#homecontact .homecontact-item {
		width: 30%;
		margin: 0;
		flex-direction: row;
	}
	#homecontact .homecontact-item .homeheroicon {
		height: 100px;
		width: 100px;
	}
	#homecontact .homecontact-item .homeheroicon img {
		object-fit: contain;
	}
	#homecontact .homecontact-item .homecontact-info {
		width: 100%;
		text-align: left;
		padding-left: 20px;
	}
	/* End contact  */
}
/* End Media Query For Tablet */

/* Media Query For Desktop */
@media only screen and (min-width: 1200px) {
	/* homeheader */
	#homeheader .homehamburger {
		display: none;
	}
	#homeheader .homenav-list ul {
		position: initial;
		display: block;
		height: auto;
		width: fit-content;
		background-color: transparent;
	}
	#homeheader .homenav-list ul li {
		display: inline-block;
	}
	#homeheader .homenav-list ul li a {
		font-size: 1.8rem;
	}
	#homeheader .homenav-list ul a:after {
		display: none;
	}
	/* End homeheader */

	#homeservices .homeheroservice-bottom .homeheroservice-item {
		flex-basis: 22%;
		margin: 1.5%;
	}
}

@media(max-width: 1100px){
	.homemessageForm .halfWidth{
		flex-basis: 100%;
	}
}
@media(max-width: 900px){
	.homecontact-container .homerow{
		flex-wrap: wrap;
	}
	.homerow section.left, .homerow section.homeright{
		flex-basis: 100%;
		margin: 0px;
	}
}

/* End  Media Query For Desktop */