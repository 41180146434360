/* Harshith Reddy Vemula (1001876993), Shashank Tatipalli (1002069335), Mohammed Sohail Sayed (1002076185),
 Hemanth Thota (1002084434), Kushal Shah (1002117623)
*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
 

.loginclass {
    position: relative;
    background: rgba(0, 0, 0, 0.9);
    font-family: sans-serif;
}

.login-wrapper {
   
    content: "";
    background: url('../assets/bg.jpg') no-repeat center;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
   height: 100vh;
    z-index: -1; 
  }
  
.loginlogo {
    width: 300px;
    position: absolute;
    top: 20px;
    left: 30px;
}
  .login-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loginform {
    position: relative;
    width: 100%;
    max-width: 380px;
    padding: 80px 40px 40px;
    background: rgba(0,0,0,0.7);
    border-radius: 10px;
    color: #b3b3b3;
    box-shadow: 0 15px 25px rgba(0,0,0,0.5);
  }
  .loginform::before {
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    /* background: rgba(255,255,255, 0.08); */
    transform: skewX(-26deg);
    transform-origin: bottom left;
    border-radius: 10px;
    pointer-events: none;
  }
  .loginform img {
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
    width: 100px;
    background: rgba(255,255,255, 0.8);
    border-radius: 50%;
  }
  .loginform h2 {
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 2rem;
    color: whitesmoke;
  }
  .loginform .logininput-group {
    position: relative;
  }
  .loginform .logininput-group input {
    width: 100%;
    padding: 10px 0;
    font-size: 1.8rem;
    letter-spacing: 1px;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    background-color: transparent;
    color: inherit;
  }
  .loginform .logininput-group label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 1.5rem;
    pointer-events: none;
    transition: .3s ease-out;
  }
  .loginform .logininput-group input:focus + label,
  .loginform .logininput-group input:valid + label {
    transform: translateY(-18px);
    color: whitesmoke;
    font-size: 1.3rem;
  }
  .loginsubmit-btn {
    display: block;
    margin-left: auto;
    border: none;
    outline: none;
    background: whitesmoke;
    width: 100%;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  .forgot-pw {
    color: #b3b3b3;
    float: right;
    text-decoration: none;
    margin-top: 5%;
  }
  .forgot-pw:hover {
    text-decoration: underline;
  }
  .login-para {
    margin-top: 20%;
    color: #b3b3b3;
  }
  .login-link {
    text-decoration: none;
    color: whitesmoke;
  }
  .login-link:hover {
    text-decoration: underline;
    
  }
  #forgot-pw {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    z-index: 1;
    background: gray;
    opacity: 0;
    transition: 0.6s;
  }
  #forgot-pw:target {
    height: 100%;
    opacity: 1;
  }
  .loginclose {
    position: absolute;
    right: 1.5rem;
    top: 0.5rem;
    font-size: 2rem;
    font-weight: 900;
    text-decoration: none;
    color: inherit;
  }

  @media screen and (max-width: 768px) {
    .loginform {
      padding: 20px;
    }
  
    .login-para {
      margin-top: 10%;
    }

    .loginlogo{
      width: 80px;
    position: absolute;
    top: 10px;
    left: 10px;
    }
  }