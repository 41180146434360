/* Harshith Reddy Vemula (1001876993), Shashank Tatipalli (1002069335), Mohammed Sohail Sayed (1002076185),
 Hemanth Thota (1002084434), Kushal Shah (1002117623)
*/

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'sans-serif';
}

.pccontainer{
    position: relative;
    width: 100%;
}
.pcsidebar{
    position: fixed;
    width: 300px;
    height: 100%;
    background: rgba(0,0,0,0.9);
    overflow-x: hidden;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    z-index: 2;
}

.pcsidebar ul li{
    width: 100%;
    list-style: none;
}
.pcchatBody{
    height: 45vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pcchat{
    width: 100%;
    height: 45vh;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, white);
    background: rgba(40, 38, 38, 0.919);
} 
.pcheader i{
    color: white;
    padding: 15px;
}
.pcheader h3{
    color: white;
    padding: 15px;
}
.PcChatbody1{
    flex: 1;
    color: black;
    background: rgba(0, 0, 0, 0.1);
    padding: 15px, 15px;
}
.pcmessage{
    background: white;
    padding: 10px;
    color: black;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 15px;
}
.user_message{
    margin-left: auto;
    background: white;
    color: black;
}
.pcfooter form{
    display: flex;
}
.pcfeedback1 form input{
    flex: 1;
    height: 40px;
    border: none;
    outline: none;
    padding-left: 5px;
    font-size: 16px;
}
.pcfeedback1 form button{
    width: 100px;
    font-size: 18px;
    border: none;
    outline: none;
    background: rgba(0, 0 , 0, 0.3);
    color: white;
    cursor: pointer;
}
.pcfeedback1 form button:hover{
    background: #333;
    transition: 0.2s ease;
}
.pcsidebar ul li:hover{
    background-color: #444;
}
.pcsidebar ul li a{
    width: 100%;
    text-decoration: none;
    color: white;
    height: 60px;
    display: flex;
    align-items: center;
}

.pcsidebar ul li:first-child{
    line-height: 60px;
    margin-bottom: 20px;
    font-weight: 600;
    border-bottom: 1px solid #fff;
}

.pcsidebar ul li a i{
   min-width: 60px;
   font-size: 24px;
   text-align: center;
}

.pcsidebar .pctitle{
    padding: 0 10px;
    font-size: 20px;
}
.pcsidebar .pctitle1{
    padding: 0 10px;
    font-size: 20px;
}

.pcmain{
    position: absolute;
    width: calc(100% - 300px);
    min-height: 100vh;
    left: 300px;
    background: white;
}

.pctop-bar{
    position: fixed;
    height: 60px;
    width: calc(100% - 300px);
    background: whitesmoke;
    display: grid;
    grid-template-columns: 10fr 0.4fr 1fr;
    grid-gap: 5px;
    align-items: center;
    color: #444;
    padding: 0 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.pctop-bar .pcsearch{
    position: relative;
    max-width: 400px;
}

.pcsearch input{
    width: 100%;
    min-width: 128px;
    height: 40px;
    padding: 0 40px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    
    background: white;
}

.pcsearch i{
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
}

.pcuser{
    position: relative;
    width: 50px;
    height: 50px;
}

.pcuser img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}

.pccards{
    margin-top: 60px;
    width: 100%;
    padding: 35px 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.pccards .pccard{
    background: linear-gradient(30deg, rgb(42, 39, 39), rgb(46, 41, 41));
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}

.pcnumber{
    font-size: 35px;
    font-weight: 500;
    color: aliceblue;
}

.pccard-name{
    color: white;
    font-weight: 600;
}
.pcicon-box i{
    font-size: 45px;
    color: antiquewhite;
}

.pctables{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    align-items: self-start;
    padding: 0 20px 20px 20px;
}

.pcAllRoles{ 
    min-height: 350px;
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0, 0.2);
}

.pcAllcourses{
    min-height: 350px;
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0, 0.2);
}

.pcAllRoles table tbody td:last-child{
    white-space: nowrap;
}
.pcheading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #050404;
}

.pcbtn{
   padding: 5px 10px; 
   background: black;
   color: whitesmoke;
   text-decoration: none;
   text-align: center;
}

table{
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;
}
thead td{
    font-weight: 600;
    color: black;
}
table tr{
    border-bottom: 1px solid rgba(0, 0 , 0, 0.1);
}
tbody tr:hover{
    background: #333;
    color: aliceblue;
}
table tbody tr:last-child{
    border-bottom: none;
}
td{
    padding: 9px 5px;
}
.pclogo {
    width: 300px;
    position: absolute;
    top: 20px;
    left: 5px;
    height: 40px;
}

.pcfeedback1{
    width: 100%;
    /* max-width: 600px; */
    margin: 50px auto;
    padding: 10px;
    margin-bottom: 10px;
padding: 10px;
border: none;
border-radius: 5px;
background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2);
}

.pcfeedback1 h1{
text-align: center;
margin-bottom: 20px;
color: #333;

}

.pcfeedback1 form input,
form textarea
{
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f7f7f7;
    color: #333;
}
.pcfeedback1 form input:focus,
form textarea:focus{
    outline: none;
    box-shadow: 0 0 5px #333;
}

 form button{
    display: block;
    width: 30%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #333;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease;
}

@media(max-width:1090px){
    .pcsidebar{
        width: 60px;
    }
    .pcchatBody{
        display: none;
    }
    .pcmain{
        width: calc(100% - 70px);
        left: 60px;
    }
    .pctop-bar{
        width: calc(100% - 60px);
    }
}

@media(max-width:860px){
    .pccards{
       grid-template-columns: repeat(2, 1fr); 
    }
    .pctables{
        grid-template-columns: 1fr;
    }
}

@media(max-width:660px){
    .pccards{
        grid-template-columns: 1fr; 
     }
     .pcAllRoles td:nth-child(3){
        display: none;
     }
     .pcAllRoles td:nth-child(2){
        display: none;
     }
}
@media(max-width:576px){
    .pccards{
        grid-template-columns: 1fr; 
     }
     .pcAllRoles td:nth-child(2){
        display: none;
     }
     .pcAllRoles td:nth-child(4){
        display: none;
     }
     .pcAllRoles td:nth-child(6){
        display: none;
     }
}

@media(max-width:420px){
    .pcAllRoles, 
    .pcAllcourses{
        font-size: 70%;
        padding: 10px;
        min-height: 200px;
    }
    .pccards .tables{
        padding: 10px;
    }
    .pcsearch input{
        padding: 0 10px;
    }
    .pcuser{
        width: 40px;
        height: 40px;
    }
}